<template>
  <div class="modal" id="escrowModal">
    <div class="modal-bg" @click="$emit('close')"></div>
    <div id="open-escrow-modal-block" class="modal-block">
      <div class="modal-header">
        <h3>Closing</h3>
      </div>
      <div id="modal-body-scroll" class="modal-body">
        <div class="modal-body-container">
          <div class="form-group mb-3">
            <label class="required" for="signing_date">Signing Date</label>
            <input id="signing_date" v-model="loan.signingDate" class="form-control" type="date" required>
          </div>
          <div class="form-group mb-3">
            <label class="required" for="distribution_date">Distribution Date</label>
            <input id="distribution_date" v-model="loan.distributionDate" class="form-control" type="date" required>
          </div>
        </div>
      </div>
      <div class="modal-footer d-flex justify-content-start">
        <button class="btn btn-primary submit" @click="submitForm">
          <span v-if="ui.isLoading" aria-hidden="true" class="spinner-border spinner-border-sm" role="status"></span>
          <span v-if="!ui.isLoading">Submit</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ClosingModal",
  props: {loanId: Number},
  data() {
    return {
      loan: {},
      ui: {
        isLoading: false
      },
    }
  },
  methods: {
    getLoan() {
      this.$http.get(`/api/v1/loans/show/${this.loanId}`)
        .then((res) => {
          this.loan = res.data
        })
        .catch()
    },
    submitForm() {
      this.ui.isLoading = true
      let formData = new FormData()
      formData.append('signingDate', this.loan.signingDate)
      formData.append('distributionDate', this.loan.distributionDate)

      this.$http.post('/api/v1/loans/update-closing-date/' + this.loan.id, formData)
        .then(() => {
          this.$store.dispatch('OPEN_STATUS_NOTIFICATION', this.statusNotification = {
            msg: 'Closing dates updated',
            status: 'success',
            delay: 2000
          });
          this.$emit('success')
          this.$emit('close')
        })
        .finally(() => {
          this.ui.form.isLoading = false;
        })
    },
  },
  created() {
    this.getLoan()
  }
}
</script>
<style lang="scss" scoped>
@import '../../assets/scss/vars';

#modal-body-scroll {
  position: relative;
  height: calc(100% - 50px);
  margin-bottom: 20px;
}

.modal-body-container::v-deep .multiselect {
  &.selected {
    .multiselect__tags {
      background-color: #F3F3F4;
    }

    .multiselect__single {
      background-color: #F3F3F4;
      color: #000000;
      font-size: 14px;
      letter-spacing: 0;
    }

    input {
      background-color: #F3F3F4;
    }
  }

  &.multiselect--active {
    .multiselect__tags {
      transition: all .3s;
      border: 1px solid rgba(38, 111, 229, 0.5);
      box-shadow: 0 0 0 4px rgba(38, 111, 229, 0.1);
    }
  }

  &__tags {
    padding: 12px 40px 0 15px;
    transition: all .3s;

    &:hover {
      border: 1px solid rgba(38, 111, 229, 0.2);
      box-shadow: 0 0 0 4px rgba(38, 111, 229, 0.1);
    }
  }

  &__select {
    &:before {
      top: 17%;
      position: relative;
      right: 0;
      color: #999;
      border: solid rgba(0, 0, 0, 0.46);
      margin-top: 4px;
      border-width: 0px 1px 1px 0;
      display: inline-block;
      padding: 3px;
      content: "";
      transform: rotate(44deg);
    }
  }

  &__single {
    color: rgba(0, 0, 0, 0.46);
    margin-bottom: 0;
    @media screen and (max-width: 568px) {
      font-size: 9px;
    }
  }

  &__option {
    padding: 10px 12px;
    min-height: 36px;
    transition: all .3s;
    line-height: 20px;
    font-size: 14px;
    color: #000;
    @media screen and (max-width: 568px) {
      font-size: 9px;
    }

    &--highlight {
      background: #f3f3f3;
      line-height: 20px;
      font-size: 14px;
      color: #000;
      @media screen and (max-width: 568px) {
        font-size: 9px;
      }
    }
  }

  &__placeholder {
    padding: 0;
  }
}

.escrow-document-wrapper {
  padding-bottom: 20px;
  border-bottom: 1px solid #E7E8E8;
  margin-bottom: 0;
}

.remind-me-btn {
  height: $input-height;
  max-height: $input-height;
}
</style>
